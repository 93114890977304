import map from 'lodash/map'
import get from 'lodash/get'
/*
 * Functions used for parsing Contentful data entries fetched from Contentful API
 */

import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import richtextFormatConfig from '../utils/richtext'

export function parse(entry) {
    if ('Entry' === entry.sys.type) {
        if (entry.sys.contentType && entry.sys.contentType.sys.id) {
            switch (entry.sys.contentType.sys.id) {
                case 'widgetHeroBlock':
                    return parseWidgetHeroBlock(entry)
                case 'widgetStoryBlock':
                    return parseWidgetStoryBlock(entry)
                case 'searchPanel':
                    return parseWidgetSearchPanel(entry)
                case 'pagesGroup':
                    return parseWidgetPagesGroup(entry)
                case 'widgetDonate':
                    return parseWidgetDonate(entry)
                case 'widgetLinks':
                    return parseWidgetLinks(entry)
                case 'widgetRichText':
                    return parseWidgetRichText(entry)
                case 'widgetLinkBlock':
                    return parseWidgetLinkBlock(entry)
                case 'widgetImpact':
                    return parseWidgetImpact(entry)
                case 'widgetSocialFeed':
                    return parseWidgetSocialFeed(entry)
                default:
                    const message = `Can't parse entry type ${entry.sys.contentType.sys.id}, please check the code and update`
                    console.error(message)
                    return entry.fields || message
            }
        }
    }
}

function parseWidgetStoryBlock(entry) {
    return {
        __typename: 'ContentfulWidgetStoryBlock',
        ...entry.fields,
        backgroundImage: entry.fields.backgroundImage.fields,
        story: entry.fields.story.fields,
    }
}

function parseWidgetHeroBlock(entry) {
    return {
        __typename: 'ContentfulWidgetHero',
        ...entry.fields,
        backgroundImage: entry.fields.backgroundImage.fields,
        summary: {
            childContentfulRichText: {
                html: documentToHtmlString(entry.fields.summary, richtextFormatConfig),
            },
        },
        link: get(entry, 'fields.link.fields'),

        featureList: map(entry.fields.featureList, feature => {
            if(feature.__typename === 'widgetStoryBlock') {
                return parseWidgetStoryBlock(feature)
            } else {
                const linkBlock = parseWidgetLinkBlock(feature);
                return {
                    ...linkBlock,
                    featureLink: linkBlock.link
                }
            }

        }),
    }
}

function parseWidgetSearchPanel(entry) {
    return {
        __typename: 'ContentfulWidgetSearchPanel',
        ...entry.fields,
        bgImage: entry.fields.bgImage.fields,
        featureList: map(entry.fields.featureList, feature => {
            if(feature.__typename === 'widgetStoryBlock') {
                return parseWidgetStoryBlock(feature)
            } else {
                const linkBlock = parseWidgetLinkBlock(feature);
                return {
                    ...linkBlock,
                    featureLink: linkBlock.link
                }
            }

        }),
    }
}

function parseWidgetPagesGroup(entry) {
    return {
        __typename: 'ContentfulPageGroup',
        contentful_id: entry.sys.id,
        ...entry.fields,
        image: entry.fields.image ? entry.fields.image.fields : null,
    }
}

function parseWidgetDonate(entry) {
    return {
        __typename: 'ContentfulWidgetDonate',
        ...entry.fields,
        notice: {
            notice: entry.fields.notice,
        },
    }
}

function parseWidgetLinks(entry) {
    return {
        __typename: 'ContentfulWidgetLinks',
        ...entry.fields,
        image: entry.fields.image.fields,
        links: parseLinkList(entry.fields.links),
    }
}

function parseWidgetRichText(entry) {
    return {
        __typename: 'ContentfulWidgetRichText',
        ...entry.fields,
        content: {
            childContentfulRichText: {
                html: documentToHtmlString(entry.fields.content, richtextFormatConfig),
            },
        },
    }
}

function parseWidgetLinkBlock(entry) {
    return {
        __typename: 'ContentfulWidgetLinkBlock',
        ...entry.fields,
        summary: {
            summary: entry.fields.summary,
        },
        backgroundImage: entry.fields.backgroundImage.fields,
        buttonLink: parseLinkList(entry.fields.link),
    }
}

export function parseLinkList(linkList) {
    return linkList.map(link => {
        let typename
        if (link.sys.contentType.sys.id === 'genericPage') {
            typename = 'ContentfulPageGeneric'
        } else if (link.sys.contentType.sys.id === 'guideline') {
            typename = 'ContentfulPageGuideline'
        } else if (link.sys.contentType.sys.id === 'pageStory') {
            typename = 'ContentfulPageStory'
        } else if (link.sys.contentType.sys.id === 'widgetsPage') {
            typename = 'ContentfulPageWidgets'
        } else if (link.sys.contentType.sys.id === 'pagesGroup') {
            typename = 'ContentfulPageGroup'
        } else if (link.sys.contentType.sys.id === 'directoryOfServices') {
            typename = 'ContentfulPageDirectoryOfServices'
        } else if (link.sys.contentType.sys.id === 'customPage') {
            typename = 'ContentfulPageCustom'
        } else if (link.sys.contentType.sys.id === 'pageDonation') {
            typename = 'ContentfulPageDonation'
        } else if (link.sys.contentType.sys.id === 'link') {
            typename = 'ContentfulWidgetLink'
        }

        const linkObj =  {
            ...link.fields,
            __typename: typename,
        }

        if(link.fields.icon) {
            linkObj.icon = link.fields.icon.fields
        }

        return linkObj
    })
}

function parseWidgetImpact(entry) {
    return {
        __typename: 'ContentfulWidgetImpact',
        ...entry.fields,
        introduction: {
            introduction: entry.fields.introduction,
        },
        items: parseLinkList(entry.fields.items),
        link: entry.fields.link.fields
    }
}

function parseWidgetSocialFeed(entry) {
    return {
        __typename: 'ContentfulWidgetSocialFeed',
        ...entry.fields,
    }
}
