import React from 'react'
import ReactDom from 'react-dom/server'
import { MARKS, INLINES, BLOCKS } from '@contentful/rich-text-types'
import Gallery from '../components/common/Gallery'
import ImageSmartWidget from '../components/widgets/ImageSmartWidget'
import ScrollableAnchor from 'react-scrollable-anchor'
import get from 'lodash/get'
import map from 'lodash/map'
import { buildURL, CONTENTFUL_ENTRY_TYPES } from './url'
import assets from '../assets/assets.json'
import { isArray, isObject } from 'lodash'

const locale = 'en-NZ'

export default {
    renderNode: {
        [INLINES.ASSET_HYPERLINK]: renderAssetHyperlink,
        [INLINES.ENTRY_HYPERLINK]: renderEntryHyperlink,
        [INLINES.EMBEDDED_ENTRY]: renderEntryByType,
        [INLINES.HYPERLINK]: renderHyperlink,
        [BLOCKS.EMBEDDED_ENTRY]: renderEntryByType,
        [BLOCKS.EMBEDDED_ASSET]: renderAssetByType,
        [BLOCKS.PARAGRAPH]: renderParagraph,
        [BLOCKS.HEADING_3]: renderH3,
    },
    /*
     * Defines custom html string for each mark type like bold, italic etc..
     */
    renderMark: {
        [MARKS.BOLD]: text => <strong>{text}</strong>,
        [MARKS.SUBSCRIPT]: text => <sub>{text}</sub>,
        [MARKS.SUPERSCRIPT]: text => <sup>{text}</sup>,
    },
}

function errorRendering(node){
    return <div>Error with Entry. This could mean the Entry you're trying to access it's not available anymore. Please review it in the CMS: ${JSON.stringify(node)}</div>
}

function renderEntryByType(node) {
    try {
        const typeId = get(node, 'data.target.sys.contentType.sys.id')

        if (!typeId) {
            return errorRendering(node);
        }

        if (typeId === 'alert') {
            return renderAlert(node)
        }

        if (typeId === 'table') {
            return renderTable(node)
        }

        if (typeId === 'anchor') {
            return renderAnchor(node)
        }

        if (typeId === 'gallery') {
            return renderGallery(node)
        }

        if (typeId === 'video') {
            return renderVideo(node)
        }

        if (typeId === 'screenCast') {
            return renderScreencast(node)
        }

        if (typeId === 'imageSmart') {
            return renderImageSmart(node)
        }

        if (typeId === 'embed3rdParty') {
            return renderEmbed3rdParty(node)
        }

        if (typeId === 'widgetImage') {
            return renderWidgetImage(node);
        }

        if (typeId === 'widgetEmbedded') {
            return renderWidgetEmbed(node);
        }

        return renderGenericEntry(node)
    } catch (error) {
        console.warn(`Error during rendering in rixhtext react node ${JSON.stringify(node)} : ${error}`)
        return errorRendering(node);
    }
}

/**
 * This method returns the value of a field. If it has a property with the same value as locale it returns that or else just the field.
 * @param {Object} node
 * @param {String} fieldPath - Path to the field. e.g.: fields.title
 */
function getField(node, fieldPath) {
    const field = get(node, fieldPath)
    return get(field, locale, field)
}

function renderH3(node, children) {
    let value = children.join('');
    if (value.includes("[object Object]")) {
        value = map(children, (child, i) => {
            if (isObject(child)) {
                return isArray(get(child, 'props.children')) ? get(child, 'props.children').join('') : get(child, 'props.children')
            }
            return child
        })
        value = value.join('')
    }
    const name = value ? value.trim().replace(/[^\w\s\d-]/gi, '').replace(/\s+/g, '-').toLowerCase() : ''
    return <h3 id={name} name={name}>{children}</h3>
}

function renderAnchor(node) {
    const title = getField(node, 'data.target.fields.title');
    const slug =  getField(node, 'data.target.fields.slug');
    return <ScrollableAnchor id={`${slug}`}><div>{title}</div></ScrollableAnchor>
}

function renderEmbed3rdParty(node) {
    const title = getField(node, 'data.target.fields.title');
    const url = getField(node, 'data.target.fields.url');

    if (url.startsWith('<svg')) {
        return  <svg dangerouslySetInnerHTML={{__html: url}} />
    }
    if (url.startsWith('<img')) {
        return <img dangerouslySetInnerHTML={{__html: url}} />
    } 
    if (url.startsWith('<iframe')) {
        return <iframe dangerouslySetInnerHTML={{__html: url}} />
    }
    return (
        <div className="embed-responsive embed-responsive-16by9">
            <iframe
                title={title}
                className="embed-responsive-item"
                src={url}
                frameBorder="0"
                allowFullScreen
            />
        </div> 
    );
}

function renderGallery(node) {
    const images = node.data.target.fields['images'][locale] || node.data.target.fields['images']
    const items = map(images, image => {
        const file = getField(image, 'fields.file')
        const title = getField(image, 'fields.title')
        const description = getField(image, 'fields.description')

        return {alt: description, title, src: file.url}
    })

    const position = getField(node, 'data.target.fields.position')
    const wrapText = getField(node, 'data.target.fields.wrapText')
    const title = getField(node, 'data.target.fields.title')
    const slug = getField(node, 'data.target.fields.slug')
    const data = { id: slug, position, title, images: items, wrapText }
    return <Gallery data={data} />
}

function renderAlert(node) {
    return <div className='alert'>${node.data.target.fields.text[locale] || node.data.target.fields.text}</div>
}

function renderTable(node) {
    const contentFieldName = 'test'
    const value = getField(node, `data.target.fields.${contentFieldName}`)
    const isDosageTable = getField(node, 'data.target.fields.isDosageTable') || false
    const tableName = getField(node, 'data.target.fields.title')
    const slug = getField(node, 'data.target.fields.slug')

    // Removing styles from table td
    const tableUpdated = value.replace(/style="[^"]*"/g, "").replace(/<a/g, `<a target="_blank"`);
    return <div dangerouslySetInnerHTML={{__html: `<div className='custom-entry table-responsive ${isDosageTable ? 'dosage-table' : ''}' data-name='${tableName}' id='${slug}'>${tableUpdated}</div>`}} />
}

/**
 * When we recieve an entry that we do not recognise we show a warning in the console and try to render something so developers would get a hint on what's wrong.
 * @param {Object} node
 * @returns {String} html string
 */
function renderGenericEntry(node) {
    console.warn(`\nThere's no render method for generic entry of type ${node.data.target.sys.contentType.sys.id}`)
    const fields = node.data.target.fields
    const field = fields.name || fields.title || fields[0] // We are trying to guess an existing field to show something.
    const value = !field ? 'empty' : field[locale] || field

    return <div className='unknown-entry'>
            <p>Please implement a render method to display the entry: {value}</p>
        </div>
}

function getAssetType(node) {
    if(node.data.target.fields && node.data.target.fields.file){
        const file = node.data.target.fields.file[locale] || node.data.target.fields.file
        return file.contentType;
    } else return null;
}

/**
 * This method will render assets depending if it's an image or a link to download an asset (pdf/zip/etc) or if it doesn't recognize the asset it will write a warning in the terminal and render the asset as a link.
 * @param {Object} node 
 * @param {Function} content
 * @returns {String} html string
 */
function renderAssetByType(node, content) {
    const type = getAssetType(node) || 'image/jpeg'

    if (/^image\/.+/i.exec(type)) {
        return renderImage(node)
    }

    return renderGenericAsset(node, content)
}

function renderImage(node) {
    try {
        if(node.data.target.fields && node.data.target.fields.file){
            const src = node.data.target.fields.file[locale]
                ? node.data.target.fields.file[locale].url
                : node.data.target.fields.file.url
            const title = node.data.target.fields.title[locale] || node.data.target.fields.title
            return <div id={`asset-${getSlug(title)}`} className="image-container text-center">
                        <img src={src} alt={title} title={title} onContextMenu={(e)=> e.preventDefault()}/>
                    </div>
        } else return errorRendering(node);
    } catch (error) {
        console.warn(`Error when rendering node ${JSON.stringify(node)} : ${error}`);
        return errorRendering(node);
    }
}

/**
 * This method shows a warning for a component that we don't support.
 * @param {Object} node
 * @returns {String} html string
 */
function renderGenericAsset(node, children) {
    const fields = get(node, 'data.target.fields')
    const file = get(fields, `file[${locale}]`, fields.file)
    console.warn(`\nThere's no render method for generic asset of type ${file.contentType}`)

    return renderHyperlink(node, children)
}

/**
 * This method will render assets as anchor hyperlinks if we regonize them as such (pdf, zip, etc) or will create local links prefixed with "asset-". For example a link to an image/table that exists in the same page.
 * @param {Object} node
 * @param {Function} children
 * @returns {String} html string
 */
function renderAssetHyperlink(node, children) {
    const type = getAssetType(node)
    if(!type){ 
        console.warn(`Error when rendering node ${JSON.stringify(node)}`);
        return errorRendering(node);
    }
    if (/^image\/.+/i.exec(type)) {
        const assetTitle = getField(node, 'data.target.fields.title') || ''
        const title = children.length ? children : assetTitle
        return <a href={`#${getSlug(assetTitle, 'asset')}`} className="asset-link">{title}</a>
    }

    return renderHyperlink(node, children)

}

function renderVideo(node) {
    const fields = get(node, 'data.target.fields')
    const videoUrl = get(fields, `videoUrl[${locale}]`, fields.videoUrl)
        .replace('youtu.be', 'www.youtube.com/embed')
        .replace('watch?v=', 'embed/')
        .replace('vimeo.com', 'player.vimeo.com/video')

    const title = get(fields, `title[${locale}]`, fields.title)
    return <div className="embed-responsive embed-responsive-16by9">
        <iframe
            title={title}
            className="embed-responsive-item"
            src={videoUrl}
            frameBorder="0"
            allowFullScreen
        ></iframe>
        </div>
}

function renderImageSmart(node) {
    const file = !node.data.target.fields.media ? undefined : node.data.target.fields.media.fields ? node.data.target.fields.media.fields.file : node.data.target.fields.media[locale].fields.file[locale]
    if(file && file.contentType.includes('image/')){
        const enableHyperlink = !node.data.target.fields.enableHyperlink ? '' : node.data.target.fields.enableHyperlink[locale] ? node.data.target.fields.enableHyperlink[locale] : node.data.target.fields.enableHyperlink
        const hyperlink = !node.data.target.fields.hyperlink ? '' : node.data.target.fields.hyperlink[locale] ? node.data.target.fields.hyperlink[locale] : node.data.target.fields.hyperlink
        const title = !node.data.target.fields.title ? '' : node.data.target.fields.title[locale] ? node.data.target.fields.title[locale] : node.data.target.fields.title
        const position = getField(node.data.target.fields, 'position') ? getField(node.data.target.fields, 'position').toLowerCase() : 'left';
        let width = !node.data.target.fields.width ? 100 : node.data.target.fields.width[locale] ? node.data.target.fields.width[locale] : node.data.target.fields.width
        width = width > 100? 100 : width
        
        const data = {file: file, enableHyperlink: enableHyperlink, hyperlink: hyperlink, title: title, position, width: width}
        return <ImageSmartWidget data={data} />;
    }
    
    return renderGenericEntry(node)
}

function renderEntryHyperlink(node, children) {
    const type = get(node, 'data.target.sys.contentType.sys.id')
    const slug = getField(node, 'data.target.fields.slug')
    const text = children.length ? children : getField(node, 'data.target.fields.title')

    const isExternalReference = type && Object.values(CONTENTFUL_ENTRY_TYPES).includes(type)
    if(isExternalReference) {
        const url = buildURL({
            slug: slug,
            userSection: getField(node, 'data.target.fields.userSection'),
            sys: node.data.target.sys
        })
        return <a href={url} target="_blank" className="entry-link">{text}</a>
    }

    const url = slug ? `#${slug}` : getField(node, 'data.target.fields.url')
    const isAnchor = url ? url.includes('#') : false
    const targetAttr = isAnchor ? '' : '_blank'
    return <a href={url} target={targetAttr} className="entry-link">{text}</a>
}

function shouldOpenInNewTab(uri) {
    return !/^mailto:.*/.test(uri) && !/^tel:.*/.test(uri);
}

function renderHyperlink(node, children) {
    let uri = getField(node, 'data.uri')
    if(!uri) {
        const file = getField(node, 'data.target.fields.file');
        if(file) {
            uri = file.url
        }
    }

    const content = children.length ? children : getField(node, 'data.target.fields.title')

    if(!uri) {
        uri = "";
    }
    const preview = typeof sessionStorage !== 'undefined' && sessionStorage.getItem('preview')
    const assetId = getField(node, 'data.target.sys.contentful_id');
    const shortURL = assets[assetId];
    if(shortURL && (!preview || preview === 'false')) {
        uri = shortURL;
    }

    // New emails open in the same tab STAR-284
    // const targetAttr = shouldOpenInNewTab(uri) ? "_blank" : "";
    const isAnchor = uri ? uri.includes('#') : false
    const targetAttr = isAnchor ? "" : "_blank"
    return <a href={uri} target={targetAttr}>{content}</a>
}

function getSlug(title, prefix) {
    const slug = title
        .replace(/[^\w\s\d-]/gi, '')
        .replace(/\s+/g, '-')
        .toLowerCase()

    return prefix ? `${prefix}-${slug}` : slug
}

function renderParagraph(node, children) {
    const paragraph = ReactDom.renderToString(children)
    if(paragraph === '') return <p>&nbsp;</p>

    return <p dangerouslySetInnerHTML={{__html: paragraph.replace(/\n/g, '<br>')}} /> 
}

function renderScreencast(node) {
    const fields = get(node, 'data.target.fields')
    const embedCode = get(fields, `embedCode[${locale}]`, fields.embedCode)
    const title = get(fields, `title[${locale}]`, fields.title)

    const regexp = RegExp('src="(.*?)"', 'g')

    const src = regexp.exec(embedCode)

    if (src == null || src[1] == null) {
        return 'Please check ScreenCast embed code'
    }

    return <div className="embed-responsive embed-responsive-16by9">
        <iframe
            title={title}
            className="embed-responsive-item"
            src={src[1]}
            frameBorder="0"
            allowFullScreen
        />
        </div>
}


function renderWidgetImage(node) {
    const images = node.data.target.fields['images'][locale] || node.data.target.fields['images']
    if(!images) return <div></div>;
    if (images.length > 1) {
        return renderGallery(node)
    } 
    const file  = getField(images[0], 'fields.file')
    if(file && file.contentType.includes('image/')){
        const enableHyperlink = getField(node, 'data.target.fields.enableHyperlink')
        let hyperlink = getField(node, 'data.target.fields.hyperlink')
        const title = getField(node, 'data.target.fields.title')
        const position = getField(node.data.target.fields, 'position')
        let width = !node.data.target.fields.width ? 100 : node.data.target.fields.width[locale] ? node.data.target.fields.width[locale] : node.data.target.fields.width
        width = width > 100 ? 100 : width
        const wrapText = getField(node, 'data.target.fields.wrapText')
        const hyperlinkEntryArray = getField(node, 'data.target.fields.hyperlinkEntry')
        if(hyperlinkEntryArray) {
            const hyperlinkEntry = hyperlinkEntryArray[0]

            if(hyperlinkEntry.sys.contentType.sys.id === "media"){
                const media = getField(hyperlinkEntry, 'fields.media');
                const assetId = getField(media, 'sys.contentful_id');
                const shortURL = assets[assetId];
                hyperlink = shortURL;
            }
            else {
                hyperlink = buildURL({ ...hyperlinkEntry })
            }
        }

        const data = {file, wrapText, enableHyperlink, hyperlink, title, position: position ? position.toLowerCase() : 'left', width}
        return <ImageSmartWidget data={data} />;
    }
    
    return renderGenericEntry(node);
}
function renderWidgetEmbed(node) {
    const fields = get(node, 'data.target.fields');
    const title = get(fields, `title[${locale}]`, fields.title);
    const type = get(fields, `type[${locale}]`, fields.type);
    const urlOrCode = get(fields, `url[${locale}]`, fields.url);
    const htmlCode = get(fields, 'htmlCode.fields.file.url', '');
    const htmlUrl = get(fields, `htmlCode[${locale}][fields][file][${locale}][url]`, htmlCode);
    
    if (type.toLowerCase() === 'url') {
        const url = urlOrCode.replace('youtu.be', 'www.youtube.com/embed')
            .replace('watch?v=', 'embed/')
            .replace('vimeo.com', 'player.vimeo.com/video');
        
        return (
            <div class="embed-responsive embed-responsive-16by9">
                <iframe
                    title={title}
                    class="embed-responsive-item"
                    src={url}
                    frameBorder="0"
                    allowFullscreen
                />
            </div>
        );
    }

    if (type.toLowerCase() === 'embed file') {
        return (
            <iframe
                title={title}
                type="text/html"
                data-embed-id={title.replace(/ /g, "_")}
                data-embed-url={htmlUrl}
                id="custom-embed"
                class="w-100"
                frameborder="0"
                allowfullscreen>
            </iframe>
        );
    }
    const regexp = RegExp('src="(.*?)"', 'g');

    const src = regexp.exec(urlOrCode);

    if (src == null || src[1] == null) {
        return 'Please check ScreenCast embed code';
    }

    return (
        <div class="embed-responsive embed-responsive-16by9">
            <iframe
                title={title}
                class="embed-responsive-item"
                src={src[1]}
                frameBorder="0"
                allowFullscreen
            />
        </div>
    );
}
