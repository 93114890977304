import React, { Component } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
import PropTypes from 'prop-types'
import { buildURL } from '../../utils/url'

import { withGuidelineContext } from './../../contexts/GuidelineContext'

class ToolsItems extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        const propsToCheck = [
            'className',
            'guidelineContext.info.title',
            'guidelineContext.info.healthPathways.length', // We don't care about the actual content. Just know that the value has been updated.
            'guidelineContext.info.informationForFamilies.length',
            'guidelineContext.dosageTables.length',
            'guidelineContext.info.externalTables.length',
            'guidelineContext.info.calculators.length',
        ]

        return some(propsToCheck, prop => {
            return get(nextProps, prop) !== get(this.props, prop)
        })
    }

    renderCalculator (guidelineContext, onCalculatorsClick) {
        if(guidelineContext.info.calculators.length === 1) {
            return (
                <a href={buildURL(guidelineContext.info.calculators[0])} className="item btn btn-link" target="_blank" rel="noopener noreferrer">
                    <span data-cy="Calculators">Calculators</span>
                    <div className="icon-wrap">
                        <i className="far fa-calculator-alt icon"/>
                    </div>
                </a>
            )
        }
        else {
            return (
                <button className="item btn btn-link" onClick={onCalculatorsClick}>
                    <span data-cy="Calculators">Calculators</span>
                    <div className="icon-wrap">
                        <i className="far fa-calculator-alt icon" />
                    </div>
                </button>
            )
        }
    }

    render() {
        const {
            className,
            guidelineContext,
            onShareClick,
            onDrugDosageClick,
            onPatientInformationClick,
            onHealthPathwaysClick,
            onFormsClick,
            onCalculatorsClick,
        } = this.props

        return (
            <div className={['tool-items', className].filter(Boolean).join(' ')}>
                {(!isEmpty(guidelineContext.dosageTables) || !isEmpty(guidelineContext.info.externalTables)) && (
                    <button className="item btn btn-link" onClick={onDrugDosageClick}>
                        <span data-cy="Drug Dosage">Drug Dosage</span>
                        <div className="icon-wrap">
                            <i className="far fa-vial icon" />
                        </div>
                    </button>
                )}
                {!isEmpty(guidelineContext.info.informationForFamilies) && (
                    <button className="item btn btn-link" onClick={onPatientInformationClick}>
                        <span data-cy="Information for Families">Information for Families</span>
                        <div className="icon-wrap">
                            <i className="far fa-file-user icon" />
                        </div>
                    </button>
                )}
                {!isEmpty(guidelineContext.info.healthPathways) && (
                    <button className="item btn btn-link" onClick={onHealthPathwaysClick}>
                        <span data-cy="Health Pathways">Health Pathways</span>
                        <div className="icon-wrap">
                            <i className="far fa-hand-point-right icon" />
                        </div>
                    </button>
                )}
                {!isEmpty(guidelineContext.info.forms) && (
                    <button className="item btn btn-link" onClick={onFormsClick}>
                        <span data-cy="Forms">Forms</span>
                        <div className="icon-wrap">
                            <i className="far fa-tasks icon" />
                        </div>
                    </button>
                )}
                {!isEmpty(guidelineContext.info.calculators) && this.renderCalculator(guidelineContext, onCalculatorsClick)}
                <button className="item btn btn-link" onClick={onShareClick}>
                    <span data-cy="Share">Share</span>
                    <div className="icon-wrap">
                        <i className="far fa-share icon" />
                    </div>
                </button>
                <button className="item btn btn-link" onClick={() => window.print()}>
                    <span data-cy="Print">Print</span>
                    <div className="icon-wrap">
                        <i className="far fa-print icon" />
                    </div>
                </button>
            </div>
        )
    }
}

ToolsItems.propTypes = {
    className: PropTypes.string,
    onShareClick: PropTypes.func.isRequired,
    onHealthPathwaysClick: PropTypes.func.isRequired,
    onPatientInformationClick: PropTypes.func.isRequired,
    onDrugDosageClick: PropTypes.func.isRequired,
    onCalculatorsClick: PropTypes.func.isRequired,
}

export default withGuidelineContext(ToolsItems)
