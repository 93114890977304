import React, { Component } from 'react'
import logo from '../../assets/images/logo-starship.png'
import { SECTIONS_ARRAY } from '../../utils/url'
import SitemapWidget from '../widgets/SitemapWidget'

export default class Footer extends Component {
    render() {
        const { activeSection, hideSocials, sitemap } = this.props
        let sortedSitemap = sitemap?.sort((a, b) => {
            return SECTIONS_ARRAY.findIndex(section => section.key === a.userSection) - SECTIONS_ARRAY.findIndex(section => section.key === b.userSection);
        })
        sortedSitemap = sortedSitemap?.filter(sitemap => !sitemap.title.includes("Don't delete"))
        return (
            <footer className="app-footer">
                <div className="footer">
                    <div className="inner">
                        <div className="credits">
                            <a href="/" className="logo"><img src={logo} alt="Starship Logo" title="Starship Logo"/></a>
                            <div className="info">Our children. Better health. Brighter futures.</div>

                            {!hideSocials &&
                                <div className="social-links">
                                    <a target="_blank" rel="noopener noreferrer" href="http://www.facebook.com/starshipfoundation?ref=mf"><i className="fab fa-facebook-f"></i></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/StarshipFDN"><i className="fab fa-twitter"></i></a>
                                    <a target="_blank" rel="noopener noreferrer" href="http://www.youtube.com/user/StarshipFoundation?ob=5#p/a"><i className="fab fa-youtube"></i></a>
                                    <a target="_blank" rel="noopener noreferrer" href="http://www.instagram.com/starshipfoundation"><i className="fab fa-instagram"></i></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://mp.weixin.qq.com/s?__biz=MzAwMTU0ODM0MA==&mid=2247484335&idx=1&sn=e3089e7ba82fbf74788138503abcae66&
                                    chksm=9ad94af4adaec3e2ee1ee47a7795134d60a934156319068b9666938c868f9f9ecc1b48efa797&token=982561504&lang=zh_CN#rd&umid=141a2d93-48e1-4970-ae78-211ecf010c3f&
                                    auth=4208f9d292d4bb72367c9cdb6e8d9affc9f9dfab-b96bc4f52e6c95e0ffcdf1b8635a9b35f5b5adb4"><i className="fab fa-weixin"></i></a>
                                </div>
                            }

                            <div className="copy">&copy; Starship 2019</div>
                        </div>

                        <div className="footer-links row">
                            {
                                sortedSitemap?.map((links, index) => <SitemapWidget key={index} activeSection={activeSection} sitemap={links} />)
                            }
                        </div>
                    </div>
                </div>
                <div className="contentful-footer">
                    <a href="https://www.contentful.com/" target="_blank" rel="noopener noreferrer"><img src="https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg" width="120" alt="Powered by Contentful" title="Powered by Contentful" /></a>
                </div>
            </footer>
        )
    }
}